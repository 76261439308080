import * as localForage from 'localforage'
import axios from 'axios';
import {ColorScheme, CountryCode, Currencies} from '../enums';
// import * as ct from 'countries-and-timezones'
import ct from 'countries-and-timezones'

import env from '../config'
import UserService  from '../services/User.service';
import  UtilsService  from '../services/Utils.service';
import ReactDOM from 'react-dom';
import CustomToasters from '../Component/CustomToaster';
import convert from 'image-file-resize';
import { ColorSchemeCode } from '../enums/ColorScheme';
import { AsYouType } from 'libphonenumber-js'

const Logout = async() => {
    
    const user = await localForage.getItem('user')

    let payload = {_id : user._id, activeBrand : null};
    await UserService.Update({payload})

    localForage.clear().then(res => window.location.assign('/'))
}

function getCountryCode( input ) {
    // Set default country code to US if no real country code is specified
    const defaultCountryCode = input.substr( 0, 1 ) !== '+' ? 'US' : null;
    let formatted = new AsYouType(defaultCountryCode).input( input );
    let countryCode = '';
    let withoutCountryCode = formatted;
    
    if ( defaultCountryCode === 'US' ) {
      countryCode = '+1';
      formatted = '+1 ' + formatted;
    }
    else {
      const parts = formatted.split( ' ' );
      countryCode = parts.length > 1 ? parts.shift() : '';
      withoutCountryCode = parts.join( ' ' );
    }
    
    return {
      formatted,
      withoutCountryCode,
      countryCode,
    }
  }

const getLocationUTC = (location) => {
    if(!location.countryCode) return
    const countryTimezone = ct.getCountry(location.countryCode)


    if(!location.country || !location.state) return
    const filteredTimezone = countryTimezone.timezones.filter(timezone => timezone === location.country+'/'+location.state) || []


    if(countryTimezone && !filteredTimezone.length && countryTimezone.timezones.length)
    return ct.getTimezone(ct.getCountry(location.countryCode).timezones[0]).offset

    return ct.getTimezone(filteredTimezone[0]).offset
}

const getGoogleLocationTimezone = async (location) => {
    const url = `https://maps.googleapis.com/maps/api/timezone/json?location=${location.lat},${location.lng}&timestamp=${Date.now()}&key=${env.GOOGLE_MAP_API}`
    const result = await axios.get(url)
}

const summarisedLocation = (place) => {
    let location = {
        lat         : place.geometry.location.lat(),
        lng         : place.geometry.location.lng(),
        address     : place.formatted_address,
        country     : '',
        city        : '',
        countryCode : '',
        state       : '',
        offset   : place.utc_offset_minutes || ''
    }

    const addressTypes = {
        'administrative_area_level_1' : (address) => location.state = address.short_name,
        'locality'                    : (address) => location.city = address.long_name,
        'country'                     : (address) => {
                                            location.country     = address.long_name
                                            location.countryCode = address.short_name
                                        },
    }

    place.address_components.map(address => addressTypes[address.types[0]] && addressTypes[address.types[0]](address))
    
    if(!location.offset)
    location.offset = getLocationUTC(location) || ''

    return location
}

const getCurrency = ({location, currencyCode}) => {
    // console.log('currencyCode ', currencyCode)
    if(currencyCode) return Currencies.filter(currency => currency.value === currencyCode)[0]

    if(location){
        const currency = CountryCode.filter(country => country.code === location.countryCode)[0]
        if(currency)
        return { 
            code   : currency.currencyCode,
            symbol : currency.currencySymbol
        }
    }
}

const sort = ({array, key}) => {
    array.sort(function(a, b) {
        var keyA = a.key,
            keyB = b.key;
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
    });

} 

const resizeImage = async ({file, width, height}) => await convert({ file: file,  width: width, height: height, type: 'png'})

const getTimezone = ({location, timezoneName}) => {
    const timezones = Object.values(ct.getAllTimezones())

    if(timezoneName) return timezones.filter(timezone => timezone.name === timezoneName)[0]

    if(location){
        const selectedTimezone = timezones.filter(timezone => {
            if(timezone.country === location.countryCode && timezone.utcOffset === location.offset) return true
            else if(timezone.country === location.countryCode) return true
            else return false
        })
        if(selectedTimezone && selectedTimezone.length) return selectedTimezone[0].name
    }
  
}

const uploadImage = async ({toaster, file, desiredPath}) => {
    const form = new FormData()
    console.log('filezoop',file);
    form.append('image', file, file.name)
    return await UtilsService.UploadImage({toaster, payload: form, desiredPath})
}

const uploadVideo = async ({toaster, file, desiredPath}) => {
    const form = new FormData()
    form.append('image', file, file.name)
    return await UtilsService.UploadVideo({toaster, payload: form, desiredPath})
}

const getRandomNumber = (length = 8) => {
    return Math.floor(Math.pow(10, length-1) + Math.random()*Math.pow(10, length-1))
}

const invertColor = (hex, bw) => {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
        // http://stackoverflow.com/a/3943023/112731
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
            ? '#000000'
            : '#FFFFFF';
    }
    // invert color components
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);

    function padZero(str, len) {
        len = len || 2;
        var zeros = new Array(len).join('0');
        return (zeros + str).slice(-len);
    }

    // pad each with zeros and return
    return "#" + padZero(r) + padZero(g) + padZero(b);
}

const getAvatar = ({firstName, lastName, fontSize, className, width, heigth, bgColor, id, tableName, customers}) => {
    let randomNum  = getRandomNumber(6) // used for avatar background color
    let colorCode  = `#${randomNum}`
    let invertCode = invertColor(bgColor || colorCode)

    if(id && tableName && customers && customers.length > 0)
    {
        customers.map((element, idx)=>{
            if(element.user._id === id && !element.user.avatar && element.user.randomAvatar)
            {
                randomNum = element.user.randomAvatar.randomNum
                colorCode = element.user.randomAvatar.colorCode
                invertCode = element.user.randomAvatar.invertCode
            }
            else if(element.user._id === id && !element.user.randomAvatar)
            {
                let randomAvatar = {
                    randomNum  : randomNum,
                    colorCode  : colorCode,
                    invertCode : invertCode
                }

                customers[idx].user.randomAvatar = randomAvatar
            }
        })


        customers && customers.length > 0 && localForage.setItem(tableName, customers);
    }

    return (
        <div 
            className={className + ' borderRadius-40'} 
            style={{
                width           : width ||'40px',
                height          : heigth ||'40px',
                backgroundColor : bgColor || colorCode,
                display         : 'flex',
                flexDirection   : 'column',
                justifyContent  : 'center',
                alignItems      : 'center',
                color           : ColorSchemeCode.white,
                fontSize        : fontSize || '12px',
                textTransform   : 'uppercase'
            }}
        >
            {   
                firstName ?
                (lastName) ? firstName[0]+lastName[0] : firstName[0]+firstName[1] :
                'AV'
            }
        </div>
    )
}

const hslToHex = (h, s, l) => {
    l /= 100;
    const a = s * Math.min(l, 1 - l) / 100;
    const f = n => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
    };
    return `#${f(0)}${f(8)}${f(4)}`;
  }

  function hexToRgbA(hex){
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)';
    }
    throw new Error('Bad Hex');
}

const getQueryString = (query) => {
    return Object.entries(query).map(entry => entry[0]+'='+entry[1]).join('&')
}

const compareJSON = (objA, objB) => {
    return JSON.stringify(objA) === JSON.stringify(objB)
}

const emptyJSON = (json) => {
    return JSON.stringify(json) === '{}'
}

const showToaster = ({position, title, message, severity, delay}) => {
    const notifications = [{title, message, severity, progress: 0}]
    ReactDOM.render(<CustomToasters position={position} notifications={notifications} delay = {delay}/>, document.getElementById('Toaster'))
}

const getDate = (dateInMS, withTime=false) => {
    if(!dateInMS) return
    const timeOptions = {hour: '2-digit', minute: '2-digit'}
    return new Date(dateInMS).toLocaleDateString('en-US', {month: '2-digit', day: '2-digit', year:'numeric', ...(withTime && timeOptions)}).split('/').join('-')
}

const booleanHasAnyValue = (boolean) => {
    return typeof(boolean) === 'boolean'
}

const setCarretPosition = (e) => {
    e.focus()
    if (typeof window.getSelection != "undefined"
            && typeof document.createRange != "undefined") {
        var range = document.createRange();
        range.selectNodeContents(e);
        range.collapse(false);
        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
    } else if (typeof document.body.createTextRange != "undefined") {
        var textRange = document.body.createTextRange();
        textRange.moveToElementText(e);
        textRange.collapse(false);
        textRange.select();
    }
}

const hexTohsl = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    var r = parseInt(result[1], 16);
    var g = parseInt(result[2], 16);
    var b = parseInt(result[3], 16);

    r = r/255;
    g = g/255;
    b = b/255;
    
    var max = Math.max(r, g, b), min = Math.min(r, g, b);
    var h, s, l = (max + min) / 2;

    if(max == min){
        h = s = 0; // achromatic
    } else {
        var d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch(max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }

    s = s*100;
    s = Math.round(s);
    l = l*100;
    l = Math.round(l);
    h = Math.round(360*h);

    return { h,s,l }
}

const getHoverColor = (hex) => { 
    const { h,s,l } = hexTohsl(hex)
    return `hsl(${h},${s}%,${l+10}%)` 
}

const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const capitalizeAll = (string) => {
      const Arr = string.split(" ")
      let result = []
      Arr.map((value, index)=>(
          result.push(value.charAt(0).toUpperCase() + value.slice(1))
      ))
    return result.join(' ')
  }


  function filterByValue(array, value) {
    return array.filter((data) =>  JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  function getNumberWithOrdinal(n) {
    var s = ["th", "st", "nd", "rd"],
        v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }

  function getRandomString(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
 charactersLength));
   }
   return result;
}

function getRandomColor(firstName) {
    var letters = '0123456789ABCDEF';
    var color = '#';
    
    
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

export {
    Logout,
    getRandomColor,
    getRandomString,
    getNumberWithOrdinal,
    filterByValue,
    hexTohsl,
    uploadVideo,
    capitalizeAll,
    capitalize,
    getHoverColor,
    summarisedLocation,
    getCurrency,
    getTimezone,
    uploadImage,
    getRandomNumber,
    invertColor,
    getAvatar,
    getQueryString,
    compareJSON,
    emptyJSON,
    showToaster,
    getDate,
    booleanHasAnyValue,
    setCarretPosition,
    resizeImage,
    sort,
    hslToHex,
    hexToRgbA,
    getCountryCode
}