import { useEffect, useState, useContext } from "react"
import CustomCopytoClip from "../Component/CustomCopyToClip";
import { PngIcons, SvgIcons } from "../icons"
// import { 
//     BrandService,
//     CampaignService,
//     GrowthtoolService,
// } from "../services"
import localforage from "localforage";
import BrandService from "../services/Brand.service";
import GrowthtoolService from "../services/Growthtool.service";
import CampaignService  from "../services/Campaign.service";

import SmsIcon from "@material-ui/icons/Sms";
import styled from "styled-components";

import {
    FacebookMessengerShareButton,
    FacebookMessengerIcon
    } from "react-share";

    export const socials = [
        {
          outlet: "SMS",
          href:
            "sms:?body=https://dev.to/dsasse07/beginner-s-guide-to-jest-testing-in-react-1nig",
          background: "#7bcb20",
          color: "white",
          label: "Share via SMS",
          icon: <SmsIcon />
        }
      ];
      

const RefferalPage = () => {
    const [campaign, setCampaign] = useState(null)
    const [growthTool, setgrowthTool] = useState(null)
    const [brandz, setBrandz] = useState(null)
    const [state, setState] = useState(null)



    const onload = async () => {
        // let brand = await localforage.getItem('brand')
        const brandQuery = { _id: window.location.href.split("/")[window.location.href.split("/").length - 2], delete: false }
        let brand = await BrandService.GetPublic({ query: brandQuery })
        console.log("1stbrand",brand);
        if (brand.response.success) setBrandz(brand.response.data)
        console.log('query brand', brand)
        const campaignData = await CampaignService.GetPublicCampaign({
            query: { brandId: brand.response.data._id, campaignType: 5 },
        });
        console.log('query campaignData', campaignData)

        setCampaign(campaignData.response.data[0]);
        const query = {
            brandId: brand.response.data._id,
            campaignId: campaignData.response.data[0]._id,
            growthtoolType : "landingPage"
        };
        const { error, response } = await GrowthtoolService.GetPublicGrowthTool({
            query,
        });
        console.log("ERROR", error);
        error && setState({ ...state, apiError: error.message, loader: false });
        if (response) {

            let flag = response.data.some((e) => e.referralUrl === true) || false
            console.log('response.data',response.data);
    
            if (!flag) {
                console.log('1');
                let landings = response.data.filter((e) => e.growthtoolType == "landingPage")
                console.log('landings', landings)
                setgrowthTool([landings[0]])
            } else {
                console.log('11');
                setgrowthTool(response.data.filter((e) => e.referralUrl === true))
            }

        }
    }
    const handleTwitterShareClick = () => {
        const sharedLink = growthTool ? (brandz?.channelDomain ? 
            `https://${brandz?.channelDomain}/page/${growthTool[0]._id}/?referralCode=${window.location.href.split("/")[window.location.href.split("/").length - 1]}`:
            `https://catchly.io/page/${growthTool[0]._id}/?referralCode=${window.location.href.split("/")[window.location.href.split("/").length - 1]}`
            ) : ''

        window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(sharedLink)}`, '_blank');
    };
    const handleLinkedInShareClick = () => {
        const sharedLink = growthTool ? (brandz?.channelDomain ? 
            `https://${brandz?.channelDomain}/page/${growthTool[0]._id}/?referralCode=${window.location.href.split("/")[window.location.href.split("/").length - 1]}` :
            `https://catchly.io/page/${growthTool[0]._id}/?referralCode=${window.location.href.split("/")[window.location.href.split("/").length - 1]}` 
            ): '';

        window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(sharedLink)}`, '_blank');
    };
    const handleShareFacebookClick = () => {
        const sharedLink = growthTool ? (brandz?.channelDomain ? 
            `https://${brandz?.channelDomain}/page/${growthTool[0]._id}/?referralCode=${window.location.href.split("/")[window.location.href.split("/").length - 1]}`:
            `https://catchly.io/page/${growthTool[0]._id}/?referralCode=${window.location.href.split("/")[window.location.href.split("/").length - 1]}`
            ) : ''
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(sharedLink)}`, '_blank');
    };
    const handleWhatsAppShareClick = () => {
        const dataToShare = growthTool ? (brandz?.channelDomain ? 
            `https://${brandz?.channelDomain}/page/${growthTool[0]._id}/?referralCode=${window.location.href.split("/")[window.location.href.split("/").length - 1]}`:
            `https://catchly.io/page/${growthTool[0]._id}/?referralCode=${window.location.href.split("/")[window.location.href.split("/").length - 1]}`
            ) : ''
        window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(dataToShare)} `, '_blank');
    };
    const handleEmailClick = () => {
        const emailAddress = 'Your@email.com';
        const subject = 'Refferal URL';
        const message = growthTool ? (brandz?.channelDomain ? 
            `https://${brandz?.channelDomain}/page/${growthTool[0]._id}/?referralCode=${window.location.href.split("/")[window.location.href.split("/").length - 1]}`:
            `https://catchly.io/page/${growthTool[0]._id}/?referralCode=${window.location.href.split("/")[window.location.href.split("/").length - 1]}`
            ) : ''

        const mailtoLink = `mailto:${encodeURIComponent(emailAddress)}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`;

        window.location.href = mailtoLink;
    };


    const ShareButton = styled.button`
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: center;
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  border-radius: 100%;
  outline: none;
  border: 2px solid ${({ background }) => background};
  padding: 4px;
  transform: ${({ isActive }) => (isActive ? "scale(0.8)" : "scale(1.0)")};
  transition: all 0.2s, transform 0.2s 0.2s;
  :hover {
    background: white;
    color: ${({ background }) => background};
  }
`;


    const SocialLink = styled(ShareButton)`
  transform: none;
  transition: top 0.2s ${({ position }) => `${position * 50}ms`},
    left 0.2s ${({ position }) => `${position * 50}ms`};
  left: ${({ isActive, position }) =>
    isActive ? `${(-1) ** position * Math.ceil(position / 2) * 50}px` : "0"};
  top: ${({ isActive }) => (isActive ? `50px` : "0")};
`;




    useEffect(() => {
        onload()
    }, [])


    return (
        <>
            {
                // brandz?.referral?.show &&
                <div id="RefSharePage" style={{ flexDirection: 'column' }} className="vh-100 w-100 d-flex justify-content-center align-items-center">
                    <div className=" hpx-180">
                        {/* <img style={{ width:'100%'  }} className="w-100 h-100" src={brandz?.bannerImage || brandz?.brandCover || PngIcons.ActiveChat} /> */}
                        <img style={{ width:'100%'  }} className="w-100 h-100" src={brandz?.bannerImage || '/banner.png'} />
                    </div>
                    <div className="bigH mt_32">
                        {brandz?.referral?.title}
                    </div>
                    <div className="mt_12 myhp d-flex justify-content-center" dangerouslySetInnerHTML={{ __html: brandz?.referral?.body }}>

                    </div>
                    <div className="mt_32 wpx-482 hpx-55 mainCopy d-flex">
                        <div className="w-85  big">

{console.log('growthTool',growthTool)}
                            {growthTool && 
                            (brandz?.channelDomain ? 
                            `https://${brandz.channelDomain}/page/${growthTool[0]._id}/?referralCode=${window.location.href.split("/")[window.location.href.split("/").length - 1]}` :
                            `https://catchly.io/page/${growthTool[0]._id}/?referralCode=${window.location.href.split("/")[window.location.href.split("/").length - 1]}`)
                            }
                        </div>
                        <div className="w-15 copy d-flex justify-content-center align-items-center cp">
                            <CustomCopytoClip
                                data={growthTool && 
                                    (brandz?.channelDomain ? 
                                    `https://${brandz.channelDomain}/page/${growthTool[0]._id}/?referralCode=${window.location.href.split("/")[window.location.href.split("/").length - 1]}`:
                                    `https://catchly.io/page/${growthTool[0]._id}/?referralCode=${window.location.href.split("/")[window.location.href.split("/").length - 1]}`)}
                                icon={<SvgIcons.CopyWhite />}
                            />

                        </div>
                    </div>
                    <div className="onSocialMedia">
                        Share on social media
                    </div>

                    <div className="d-flex gap-32 mt_19 mainIcons">
                        <div className="cp" onClick={() => handleTwitterShareClick()}><SvgIcons.TwitterX /></div>
                        <div className="cp" onClick={() => handleShareFacebookClick()}><SvgIcons.FacebookX /></div>
                        <div className="cp" onClick={() => handleLinkedInShareClick()}><SvgIcons.LinkdlnX /></div>
                        {/* <div className="cp" onClick={() => handleMessengerShareClick()}><SvgIcons.MessengerX /></div> */}
                        <div className="cp" onClick={() => handleWhatsAppShareClick()}><SvgIcons.WhatsAppX /></div>
                        <div className="cp" onClick={() => handleEmailClick()}><SvgIcons.TweetX /></div>
                        {/* <FacebookMessengerShareButton url="https://www.google.com/" />
                        <FacebookShareCount url={"https://www.google.com/"} >
                        </FacebookShareCount>
                        <FacebookMessengerIcon size={32} round={true} /> */}
                        <FacebookMessengerShareButton
                        option
                            url={'https://www.google.com/'}
                            // quote={'Dummy text!'}
                            // hashtag="#muo"
                        >
                            <FacebookMessengerIcon size={32} round />
                        </FacebookMessengerShareButton>


<div>

                        <SocialLink
                            as="a"
                            href={"sms:?body=Start sharing now and unlock fantastic rewards together! 💫 Don't miss out on this opportunity to earn while spreading the word about something you love! https%3A%2F%2Fdev.to%2Fdsasse07%2Fbeginner-s-guide-to-jest-testing-in-react-1nig"}
                            target="_blank"
                            rel="noreferrer"
                            aria-label={'Share via SMS'}
                            role="button"
                            isActive={true}
                            background={'#7bcb20'}
                            color={'white'}
                            // position={0}
                            // key={0}
                        >
                            <SmsIcon style={{height: '24px', width: '24px'}} />
                        </SocialLink>
</div>
                    </div>
                </div>
            }
        </>


    )

}

export default RefferalPage