import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import RefferalPage from './Component/RefferalPage';

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/global.scss";
import Email from "./Component/Email";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/:id/:refid" component={RefferalPage}/>
          <Route exact path="/:id" component={Email}/>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
