import axios from 'axios'
import { Handlers, utils } from '../utils'
import env from '../config'
import MainService from './Main.service'
const service  = '/v4/growthtool'

const CreateGrowthTool = async({payload}) => await axios.post(env.API_URL+service+'/', payload, {headers: await MainService.getTokenHeader()})
const GetQueriedGrowthTool = async ({query}) => await axios.get(env.API_URL+service+'?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})
const GetPublicGrowthTool = async ({query}) => await axios.get(env.API_URL+service+'/public?'+utils.getQueryString(query))
const UpdateGrowthTool = async ({payload}) => await axios.put(env.API_URL+service, payload, {headers: await MainService.getTokenHeader()})

const GrowthtoolService = {
    CreateGrowthTool     : Handlers.Services(CreateGrowthTool),
    GetQueriedGrowthTool : Handlers.Services(GetQueriedGrowthTool),
    GetPublicGrowthTool  : Handlers.Services(GetPublicGrowthTool),
    UpdateGrowthTool     : Handlers.Services(UpdateGrowthTool),
}

export default GrowthtoolService
