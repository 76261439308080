import * as localForage from 'localforage'
import { BusinessType } from '../enums/enums'

const getActiveId = async () => {
    const param = window.location.pathname.split('/')[1]
    if(param.length > 10) return param
    const user = await localForage.getItem('user')
    if(user && user.activeBrand !== null) return user.activeBrand;
}

const getTokenHeader = async () => {
    const token = await localForage.getItem('token');
    const activeId = await getActiveId()
    return { 'x-auth-token': 'Bearer ' + token, 'authorize-key': activeId ? BusinessType.BRAND+'&'+activeId : '' }
}

const MainService = {
    getTokenHeader
}

export default MainService