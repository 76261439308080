import axios from 'axios'
import { Handlers, utils } from '../utils'
import env from '../config'
import MainService from './Main.service'
const service  = '/v4/brand'

const Create = async ({payload}) => await axios.post(env.API_URL+service, payload, {headers: await MainService.getTokenHeader()})

const LightSpeed = async ({payload}) => await axios.post(env.API_URL+service+'/lightspeed', payload, {headers: await MainService.getTokenHeader()})

const Get = async ({query}) => await axios.get(env.API_URL+service+'?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const Email = async ({query}) => await axios.get(env.API_URL+service+'/ref?'+utils.getQueryString(query))

const GetPublic = async ({query}) => await axios.get(env.API_URL+service+'/public?'+utils.getQueryString(query))

const Update = async ({payload}) => await axios.put(env.API_URL+service, payload, {headers: await MainService.getTokenHeader()})

const CreateSubscription = async ({payload}) => await axios.post(env.API_URL+service+'/subscription', payload, {headers: await MainService.getTokenHeader()})

const UpdateSubscription = async ({payload}) => await axios.put(env.API_URL+service+'/subscription', payload, {headers: await MainService.getTokenHeader()})

const RenewSubscription = async ({payload}) => await axios.post(env.API_URL+service+'/subscription/renew', payload, {headers: await MainService.getTokenHeader()})

const GetSubscribers = async ({query}) => await axios.get(env.API_URL+service+'/subscriber?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const SendQrCode = async ({payload}) => await axios.post(env.API_URL+service+'/sendqrcode', payload , {headers: await MainService.getTokenHeader()})

const SendPassCode = async ({payload}) => await axios.post(env.API_URL+service+'/sendPasscode', payload , {headers: await MainService.getTokenHeader()})

const BrandService = {
    Create             : Handlers.Services(Create),
    LightSpeed         : Handlers.Services(LightSpeed),
    Get                : Handlers.Services(Get),
    GetPublic          : Handlers.Services(GetPublic),
    Email          : Handlers.Services(Email),
    Update             : Handlers.Services(Update),
    CreateSubscription : Handlers.Services(CreateSubscription),
    UpdateSubscription : Handlers.Services(UpdateSubscription),
    RenewSubscription  : Handlers.Services(RenewSubscription),
    GetSubscribers     : Handlers.Services(GetSubscribers),
    SendQrCode         : Handlers.Services(SendQrCode),
    SendPassCode       : Handlers.Services(SendPassCode),
}

export default BrandService
