import axios from 'axios'
import { Handlers, utils } from '../utils'
import env from '../config'
import MainService from './Main.service'
const service  = '/v4/user'

const Get = async () => await axios.get(env.API_URL+service, {headers: await MainService.getTokenHeader()})

const Update = async ({payload}) => await axios.put(env.API_URL+service, payload, {headers: await MainService.getTokenHeader()})

const UpdateId = async ({payload}) => await axios.put(env.API_URL+service+'/updateId', payload)

const UpdatePublic = async ({payload}) => await axios.put(env.API_URL+service+'/public', payload, {headers: await MainService.getTokenHeader()})

const VerifyPassword = async({password}) => await axios.get(env.API_URL+service+'/verifyPassword?password='+password, {headers: await MainService.getTokenHeader()})

const GetByQuery = async ({query}) => await axios.get(env.API_URL+service+'/getbyQuery?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const GetByEmail = async ({query}) => await axios.get(env.API_URL+service+'/getUserByEmail?'+utils.getQueryString(query))

const GetUserDataByEmail = async ({query}) => await axios.get(env.API_URL+service+'/GetUserDataByEmail?'+utils.getQueryString(query))

const FingerPrint = async ({query}) => await axios.get(env.API_URL+service+'/fingerprint?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const ResendCode = async ({query}) => await axios.get(env.API_URL+service+'/resendCode?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const UserService = {
    Get            : Handlers.Services(Get),
    Update         : Handlers.Services(Update),
    UpdateId         : Handlers.Services(UpdateId),
    UpdatePublic   : Handlers.Services(UpdatePublic),
    VerifyPassword : Handlers.Services(VerifyPassword),
    GetByQuery     : Handlers.Services(GetByQuery),
    GetByEmail     : Handlers.Services(GetByEmail),
    FingerPrint    : Handlers.Services(FingerPrint),
    ResendCode     : Handlers.Services(ResendCode),
    GetUserDataByEmail : Handlers.Services(GetUserDataByEmail)
}

export default UserService
