import React, { useEffect } from 'react'
import BrandService from '../services/Brand.service';

function Email() {
    const searchParams = new URLSearchParams(window.location.search);
    const email = searchParams.get('email')

    useEffect(async() => {
        const a = window.location.href.split("?")[0]
        const brandQuery = { _id: a.split("/")[a.split("/").length - 2], email: email }
        let brandemail = await BrandService.Email({ query: brandQuery })
        console.log('brandemail',brandemail);
        if(brandemail.response.data[0][0].referalCode){
            window.location.href = window.location.href.split("?")[0][window.location.href.split("?")[0].length-1]==='/' ? (window.location.href.split("?")[0] + brandemail.response.data[0][0].referalCode) : (window.location.href.split("?")[0] + "/" + brandemail.response.data[0][0].referalCode);
        }
    }, [])
    
  return (
    <div>...</div>
  )
}

export default Email