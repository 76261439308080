import { useState } from "react"
const CustomCopytoClip = ({ ...props }) => {
    const [tooltip , setTooltil] = useState(false)
    const copydata = () => {
        setTooltil(true)
        navigator.clipboard.writeText(props.data)
        setTimeout(()=>{
            setTooltil(false)
        },500)

    }


    return (
        <div style={{position : 'relative'}} className="cp" onClick={() => copydata()}>
           {tooltip &&  <div className="color-white wpx-80 d-flex justify-content-center align-items-start pt_5 hpx-30 " style={{clipPath:'polygon(0% 0%, 100% 0, 100% 75%, 64% 75%, 49% 100%, 33% 76%, 0% 75%)',position : 'absolute' , top : '-40px',left : '-27px' , backgroundColor : '#84889B' , borderRadius : '10px'}}> Copied</div>}
           {props.icon}
        </div >
    )

}
export default CustomCopytoClip