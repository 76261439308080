const Currencies = [
    {
    "value":"AFN",
    "symbol":"Af"
    },
    {
    "value":"EUR",
    "symbol":"€"
    },
    {
    "value":"ALL",
    "symbol":"ALL"
    },
    {
    "value":"DZD",
    "symbol":"DA"
    },
    {
    "value":"USD",
    "symbol":"$"
    },
    {
    "value":"ARS",
    "symbol":"AR$"
    },
    {
    "value":"AMD",
    "symbol":"AMD"
    },
    {
    "value":"AUD",
    "symbol":"AU$"
    },
    {
    "value":"AZN",
    "symbol":"man."
    },
    {
    "value":"BHD",
    "symbol":"BD"
    },
    {
    "value":"BDT",
    "symbol":"Tk"
    },
    {
    "value":"BZD",
    "symbol":"BZ$"
    },
    {
    "value":"XOF",
    "symbol":"CFA"
    },
    {
    "value":"BOB",
    "symbol":"Bs"
    },
    {
    "value":"BAM",
    "symbol":"KM"
    },
    {
    "value":"BWP",
    "symbol":"BWP"
    },
    {
    "value":"NOK",
    "symbol":"Nkr"
    },
    {
    "value":"BRL",
    "symbol":"R$"
    },
    {
    "value":"BND",
    "symbol":"BN$"
    },
    {
    "value":"BGN",
    "symbol":"BGN"
    },
    {
    "value":"BIF",
    "symbol":"FBu"
    },
    {
    "value":"KHR",
    "symbol":"KHR"
    },
    {
    "value":"XAF",
    "symbol":"FCFA"
    },
    {
    "value":"CAD",
    "symbol":"CA$"
    },
    {
    "value":"CVE",
    "symbol":"CV$"
    },
    {
    "value":"CLP",
    "symbol":"CL$"
    },
    {
    "value":"CNY",
    "symbol":"CN¥"
    },
    {
    "value":"COP",
    "symbol":"CO$"
    },
    {
    "value":"KMF",
    "symbol":"CF"
    },
    {
    "value":"CDF",
    "symbol":"CDF"
    },
    {
    "value":"NZD",
    "symbol":"NZ$"
    },
    {
    "value":"CRC",
    "symbol":"₡"
    },
    {
    "value":"HRK",
    "symbol":"kn"
    },
    {
    "value":"CZK",
    "symbol":"Kč"
    },
    {
    "value":"DKK",
    "symbol":"Dkr"
    },
    {
    "value":"DJF",
    "symbol":"Fdj"
    },
    {
    "value":"DOP",
    "symbol":"RD$"
    },
    {
    "value":"EGP",
    "symbol":"EGP"
    },
    {
    "value":"ERN",
    "symbol":"Nfk"
    },
    {
    "value":"ETB",
    "symbol":"Br"
    },
    {
    "value":"GEL",
    "symbol":"GEL"
    },
    {
    "value":"GHS",
    "symbol":"GH₵"
    },
    {
    "value":"GTQ",
    "symbol":"GTQ"
    },
    {
    "value":"GBP",
    "symbol":"£"
    },
    {
    "value":"GNF",
    "symbol":"FG"
    },
    {
    "value":"HNL",
    "symbol":"HNL"
    },
    {
    "value":"HKD",
    "symbol":"HK$"
    },
    {
    "value":"HUF",
    "symbol":"Ft"
    },
    {
    "value":"ISK",
    "symbol":"Ikr"
    },
    {
    "value":"INR",
    "symbol":"Rs"
    },
    {
    "value":"IDR",
    "symbol":"Rp"
    },
    {
    "value":"IRR",
    "symbol":"IRR"
    },
    {
    "value":"IQD",
    "symbol":"IQD"
    },
    {
    "value":"ILS",
    "symbol":"₪"
    },
    {
    "value":"JMD",
    "symbol":"J$"
    },
    {
    "value":"JPY",
    "symbol":"¥"
    },
    {
    "value":"JOD",
    "symbol":"JD"
    },
    {
    "value":"KZT",
    "symbol":"KZT"
    },
    {
    "value":"KES",
    "symbol":"Ksh"
    },
    {
    "value":"KRW",
    "symbol":"₩"
    },
    {
    "value":"KWD",
    "symbol":"KD"
    },
    {
    "value":"LBP",
    "symbol":"LB£"
    },
    {
    "value":"LYD",
    "symbol":"LD"
    },
    {
    "value":"CHF",
    "symbol":"CHF"
    },
    {
    "value":"MOP",
    "symbol":"MOP$"
    },
    {
    "value":"MKD",
    "symbol":"MKD"
    },
    {
    "value":"MGA",
    "symbol":"MGA"
    },
    {
    "value":"MYR",
    "symbol":"RM"
    },
    {
    "value":"MUR",
    "symbol":"MURs"
    },
    {
    "value":"MXN",
    "symbol":"MX$"
    },
    {
    "value":"MDL",
    "symbol":"MDL"
    },
    {
    "value":"MAD",
    "symbol":"MAD"
    },
    {
    "value":"MZN",
    "symbol":"MTn"
    },
    {
    "value":"MMK",
    "symbol":"MMK"
    },
    {
    "value":"NAD",
    "symbol":"N$"
    },
    {
    "value":"NPR",
    "symbol":"NPRs"
    },
    {
    "value":"NIO",
    "symbol":"C$"
    },
    {
    "value":"NGN",
    "symbol":"₦"
    },
    {
    "value":"OMR",
    "symbol":"OMR"
    },
    {
    "value":"PKR",
    "symbol":"PKRs"
    },
    {
    "value":"PAB",
    "symbol":"B/."
    },
    {
    "value":"PYG",
    "symbol":"₲"
    },
    {
    "value":"PEN",
    "symbol":"S/."
    },
    {
    "value":"PHP",
    "symbol":"₱"
    },
    {
    "value":"PLN",
    "symbol":"zł"
    },
    {
    "value":"QAR",
    "symbol":"QR"
    },
    {
    "value":"RON",
    "symbol":"RON"
    },
    {
    "value":"RUB",
    "symbol":"RUB"
    },
    {
    "value":"RWF",
    "symbol":"RWF"
    },
    {
    "value":"SAR",
    "symbol":"SR"
    },
    {
    "value":"RSD",
    "symbol":"din."
    },
    {
    "value":"SGD",
    "symbol":"S$"
    },
    {
    "value":"SOS",
    "symbol":"Ssh"
    },
    {
    "value":"ZAR",
    "symbol":"R"
    },
    {
    "value":"LKR",
    "symbol":"SLRs"
    },
    {
    "value":"SDG",
    "symbol":"SDG"
    },
    {
    "value":"SEK",
    "symbol":"Skr"
    },
    {
    "value":"SYP",
    "symbol":"SY£"
    },
    {
    "value":"TWD",
    "symbol":"NT$"
    },
    {
    "value":"TZS",
    "symbol":"TSh"
    },
    {
    "value":"THB",
    "symbol":"฿"
    },
    {
    "value":"TOP",
    "symbol":"T$"
    },
    {
    "value":"TTD",
    "symbol":"TT$"
    },
    {
    "value":"TND",
    "symbol":"DT"
    },
    {
    "value":"TRY",
    "symbol":"TL"
    },
    {
    "value":"UGX",
    "symbol":"USh"
    },
    {
    "value":"UAH",
    "symbol":"₴"
    },
    {
    "value":"AED",
    "symbol":"AED"
    },
    {
    "value":"UYU",
    "symbol":"$U"
    },
    {
    "value":"UZS",
    "symbol":"UZS"
    },
    {
    "value":"VEF",
    "symbol":"Bs.F."
    },
    {
    "value":"VND",
    "symbol":"₫"
    },
    {
    "value":"YER",
    "symbol":"YR"
    },
    {
    "value":"ZWL",
    "symbol":"ZWL$"
    }
]

export default Currencies