import axios from 'axios'
import { Handlers, utils } from '../utils'
import env from '../config'
import MainService from './Main.service'
const service  = '/v4/campaign'

const UpdateCampaign = async ({payload}) => await axios.put(env.API_URL+service, payload, {headers: await MainService.getTokenHeader()})

const GetQueriedCampaign = async ({query}) => await axios.get(env.API_URL+service+'?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const GetPublicCampaign = async ({query}) => await axios.get(env.API_URL+service+'/public?'+utils.getQueryString(query))

const CreatePreBuiltCampaign = async({payload}) => await axios.post(env.API_URL+service+'/prebuilt', payload, {headers: await MainService.getTokenHeader()})

const CreateCampaign = async({payload}) => await axios.post(env.API_URL+service+'/', payload, {headers: await MainService.getTokenHeader()})


const CampaignService = {
    UpdateCampaign        : Handlers.Services(UpdateCampaign),
    GetQueriedCampaign    : Handlers.Services(GetQueriedCampaign),
    GetPublicCampaign     : Handlers.Services(GetPublicCampaign),
    CreatePreBuiltCampaign: Handlers.Services(CreatePreBuiltCampaign),
    CreateCampaign        : Handlers.Services(CreateCampaign),
}

export default CampaignService
